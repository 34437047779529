import React from "react";
import Grid from "@mui/material/Grid";

const Footer: React.FC = () => {

  const getAppDebugFooterText = (): JSX.Element => {
    if (process.env.NODE_ENV !== "production") {
      return <small>You are running this application in <b>{process.env.NODE_ENV}</b> mode. Service Url: {process.env.REACT_APP_OCGSERVICE_API_ROOT}</small>;
    }
    return <div />;
  };

  return (
    <div className="footer">
      <Grid container spacing={3}>
        <Grid item xs={8}>
        </Grid>
        <Grid item xs={4}>
          <a href="https://www.facebook.com/overclockedgamesllc/"><span className="ocg-logo" /></a>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {getAppDebugFooterText()}
        </Grid>
      </Grid>
    </div>
  );
};

export default Footer;