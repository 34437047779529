import React from "react";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import { AccountFlowModalComponent } from "./common/AccountFlowModal";
import { PlayerData } from "./contracts/PlayerData";
import { PlayerManagementService } from "./common/services/PlayerManagementService";
import { Collapse, AppBar, Toolbar, IconButton, ListItemIcon, ListItemText, Typography, Grid, Box, Avatar } from "@mui/material";
import { withStyles } from "tss-react/mui";
import MenuItem from "@mui/material/MenuItem";
import Menu, { MenuProps } from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LanguageIcon from "@mui/icons-material/Language";
import { NavLink } from "react-router-dom";

export interface HeaderComponentProps {
  i18n: i18n;
  t: TFunction
}

export interface HeaderComponentState {
  isMainNavCollapseOpen: boolean;
  isRegistrationModalOpen: boolean;
  userClickedLogin: boolean;
  playerData: PlayerData | null;
  profileMenuAnchor: HTMLElement | null;
  languageMenuAnchor: HTMLElement | null;
}

export class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {

  constructor(props: HeaderComponentProps) {
    super(props);
    const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
    this.state = {
      isMainNavCollapseOpen: true,
      isRegistrationModalOpen: false,
      userClickedLogin: false,
      playerData: playerData,
      profileMenuAnchor: null,
      languageMenuAnchor: null
    };
  }

  public render(): JSX.Element {

    const StyledMenu = withStyles(
      ((props: MenuProps) => (
        <Menu
          elevation={0}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          {...props}
        />
      )),
      {
        paper: {
          border: "1px solid #d3d4d5",
        },
      });

    return (
      <React.Fragment>
        <AppBar style={{ background: "#333" }} position="sticky">
          <div style={{ paddingBottom: "10px", paddingTop: "10px" }}>
            <Grid container alignContent="flex-end">
              <Grid item xs={1}>
                <IconButton
                  edge="end"
                  aria-label="language menu"
                  aria-haspopup="true"
                  onClick={this.onLanguageMenuClick}
                  color="inherit"
                >
                  <LanguageIcon />
                </IconButton>
              </Grid>
              <Grid item xs={9} sm={10} md={10}>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <IconButton
                  edge="end"
                  aria-label="profile menu"
                  aria-haspopup="true"
                  onClick={this.onProfileMenuClick}
                  color="inherit"
                >
                  {(this.state.playerData) ? <Avatar style={{ width: "24px", height: "24px", padding: 0 }}>{this.state.playerData.userName[0].toLocaleUpperCase()}</Avatar> : <AccountCircle />}
                </IconButton>
              </Grid>
            </Grid>
          </div>
        </AppBar>
        <AppBar style={{ background: "transparent", boxShadow: "none", pointerEvents: "none" }}>
          <Toolbar>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12}>
                <NavLink to="/">
                  <span className="header-image"></span>
                </NavLink>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <AccountFlowModalComponent
          i18n={this.props.i18n}
          t={this.props.t}
          isOpen={this.state.isRegistrationModalOpen}
          toggleFunction={this.toggleRegistrationModal}
          isLoginFlow={this.state.userClickedLogin}
        />
        <Box component="div" className="main-nav">
          <Grid container justifyContent="center" direction="row">
            <Grid item xs={12}>
              <div onClick={this.toggleMainMenu}>
                <span className={(this.state.isMainNavCollapseOpen) ? "toggled-button" : "toggle-button"} />
              </div>
            </Grid>
          </Grid>
          <Collapse style={{ flexBasis: "100%" }} in={this.state.isMainNavCollapseOpen}>
            <Grid container justifyContent="center" direction="row">
              <Grid item xs={3}>
                <NavLink to="/games" className="full-menu-section">
                  <span className="play-button-large"></span>
                  <Typography color="inherit" variant="h5">
                    {this.props.t("header:mainNav.playHeader")}
                  </Typography>
                  <div className="full-menu-subtitle">
                    <Typography variant="body2">{this.props.t("header:mainNav.playSubtitle")}</Typography>
                  </div>
                </NavLink>
              </Grid>
              <Grid item xs={3}>
                <NavLink to="/support" className="full-menu-section">
                  <span className="store-button-large"></span>
                  <Typography color="inherit" variant="h5">
                    {this.props.t("header:mainNav.storeHeader")}
                  </Typography>
                  <div className="full-menu-subtitle">
                    <Typography variant="body2">{this.props.t("header:mainNav.storeSubtitle")}</Typography>
                  </div>
                </NavLink>
              </Grid>
              <Grid item xs={3}>
                <NavLink to="/study" className="full-menu-section">
                  <span className="scrolls-button-large"></span>
                  <Typography color="inherit" variant="h5">
                    {this.props.t("header:mainNav.learnHeader")}
                  </Typography>
                  <div className="full-menu-subtitle">
                    <Typography variant="body2">{this.props.t("header:mainNav.learnSubtitle")}</Typography>
                  </div>
                </NavLink>
              </Grid>
              <Grid item xs={3}>
                <NavLink to="/explore" className="full-menu-section">
                  <span className="explore-button-large"></span>
                  <Typography color="inherit" variant="h5">
                    {this.props.t("header:mainNav.exploreHeader")}
                  </Typography>
                  <div className="full-menu-subtitle">
                    <Typography variant="body2">{this.props.t("header:mainNav.exploreSubtitle")}</Typography>
                  </div>
                </NavLink>
              </Grid>
              <Grid item xs>
              </Grid>
            </Grid>
          </Collapse>

        </Box>
        <StyledMenu
          id="profile-menu"
          anchorEl={this.state.profileMenuAnchor}
          keepMounted
          open={Boolean(this.state.profileMenuAnchor)}
          onClose={this.onProfileMenuClose}
        >
          {this.getProfileMenu()}
        </StyledMenu>
        <StyledMenu
          id="language-menu"
          anchorEl={this.state.languageMenuAnchor}
          keepMounted
          open={Boolean(this.state.languageMenuAnchor)}
          onClose={this.onLanguageMenuClose}
        >
          {this.getLanguageMenu()}
        </StyledMenu>
      </React.Fragment>
    );
  }

  private onLanguageMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ languageMenuAnchor: event.target as HTMLElement });
  };

  private onLanguageMenuClose = (): void => {
    this.setState({ languageMenuAnchor: null });
  };

  private onProfileMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ profileMenuAnchor: event.target as HTMLElement });
  };

  private onProfileMenuClose = (): void => {
    this.setState({ profileMenuAnchor: null });
  };

  private toggleMainMenu = (): void => {
    this.setState({ isMainNavCollapseOpen: !this.state.isMainNavCollapseOpen });
  };

  private toggleRegistrationModal = (): void => {

    const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
    if (playerData !== null) {
      this.setState({ playerData: playerData });
    }
    else {
      this.setState({ playerData: null });
    }
    this.setState({ isRegistrationModalOpen: !this.state.isRegistrationModalOpen });
  };

  private getLanguageMenu(): JSX.Element {

    const StyledMenuItem = withStyles(
      MenuItem,
      theme => ({
        root: {
          "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
              color: theme.palette.common.white,
            },
          },
        },
      }));

    return (
      <div>
        <StyledMenuItem onClick={() => {
          this.setState({ languageMenuAnchor: null });
          this.props.i18n.changeLanguage("en");
        }}>
          <ListItemText primary="English" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          this.setState({ languageMenuAnchor: null });
          this.props.i18n.changeLanguage("jp");
        }}>
          <ListItemText primary="日本語" />
        </StyledMenuItem>
      </div>
    );
  }

  private getProfileMenu(): JSX.Element {

    const StyledMenuItem = withStyles(
      MenuItem,
      theme => ({
        root: {
          "&:focus": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
              color: theme.palette.common.white,
            },
          },
        },
      }));

    if (this.state.playerData) {
      return (
        <div>
          <StyledMenuItem onClick={() => {
            this.setState({ profileMenuAnchor: null });
          }}>
            <NavLink to="/profile">
              <ListItemIcon>
                <SettingsApplicationsIcon />
              </ListItemIcon>
            </NavLink>
            <NavLink to="/profile" style={{ color: "black" }}>
              <ListItemText primary="Profile" />
            </NavLink>
          </StyledMenuItem>
          <StyledMenuItem onClick={() => {
            PlayerManagementService.ClearSession();
            this.setState({ playerData: null, profileMenuAnchor: null, userClickedLogin: false });
            window.location.reload();
          }}>
            <NavLink to="/">
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
            </NavLink>
            <NavLink to="/" style={{ color: "black" }}>
              <ListItemText primary="Logout" />
            </NavLink>
          </StyledMenuItem>
        </div>
      );
    }

    return (
      <div>
        <StyledMenuItem onClick={() => {
          this.setState({ userClickedLogin: true, profileMenuAnchor: null });
          this.toggleRegistrationModal();
        }}>
          <ListItemIcon>
            <VpnKeyIcon />
          </ListItemIcon>
          <ListItemText primary="Login" />
        </StyledMenuItem>
        <StyledMenuItem onClick={() => {
          this.setState({ userClickedLogin: false, profileMenuAnchor: null });
          this.toggleRegistrationModal();
        }}>
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText primary="Create Account" />
        </StyledMenuItem>
      </div>
    );
  }
}