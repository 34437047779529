import React, { useState, FunctionComponent } from "react";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { PlayerData } from "../contracts/PlayerData";
import { Button, Container } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import DeleteIcon from "@mui/icons-material/Delete";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { PlayerManagementDataService } from "../common/services/PlayerManagementDataService";
import { PlayerManagementService } from "../common/services/PlayerManagementService";
import { PopupNotification, VariantIcon } from "../common/PopupNotification";
import { ActionState } from "../common/ActionButton";
import ConfirmationAlert from "../common/ConfirmationAlert";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    bodyContainer: {
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    slider: {
      width: "200px",
      margin: "20px",
      paddingTop: "25px"
    },
    header: {
      margin: theme.spacing(3, 2)
    },
    formControl: {
      marginLeft: theme.spacing(1),
      margingRight: theme.spacing(1)
    },
    saveButton: {
      margin: theme.spacing(1),
      height: "40px"
    },
    button: {
      margin: theme.spacing(1),
    }
  };
});

export const ProfileActions: FunctionComponent = () => {
  const { classes } = useStyles();
  const playerData: PlayerData = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey) as PlayerData;

  const [resetPassConfirmOpen, setResetPassConfirmOpen] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [actionState, setActionState] = useState<ActionState>("Idle");
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationVariant, setNotificationVariant] = useState<keyof typeof VariantIcon>("success");
  const [notificationOpen, setNotificationOpen] = useState(false);

  const onResetPasswordClick = async (): Promise<void> => {
    setResetPassConfirmOpen(true);
  };

  const onDeleteClick = async (): Promise<void> => {
    setDeleteConfirmOpen(true);
  };

  const onResetPasswordConfirmClick = async (confirmed: boolean): Promise<void> => {

    setResetPassConfirmOpen(false);
    if (!confirmed) {
      return;
    }

    setNotificationVariant("info");
    setNotificationMessage(`Resetting Password For ${playerData.id}...`);
    setActionState("Loading");
    setNotificationOpen(true);

    try {
      await PlayerManagementDataService.ResetPassword(playerData.id);
      setNotificationVariant("success");
      setNotificationMessage("Reset Password Email Sent!");
      setActionState("Success");
    }
    catch (e) {
      setNotificationVariant("error");
      setNotificationMessage("Reset Failed! Please report to administrator@overclockedgames.com");
      setActionState("Failed");
    }
  };

  const onDeleteConfirmClick = async (confirmed: boolean): Promise<void> => {

    setDeleteConfirmOpen(false);
    if (!confirmed) {
      return;
    }

    setNotificationVariant("info");
    setNotificationMessage(`Deleting account: ${playerData.id}...`);
    setActionState("Loading");
    setNotificationOpen(true);

    try {
      await PlayerManagementDataService.DeleteAccount();
      PlayerManagementService.ClearSession();
      window.location.reload();
    }
    catch (e) {
      setNotificationVariant("error");
      setNotificationMessage("Delete Failed! Please report to administrator@overclockedgames.com");
      setActionState("Failed");
    }
  };

  const onResendVerificationClick = async (): Promise<ActionState> => {

    setNotificationVariant("info");
    setNotificationMessage(`Resending verification email to ${playerData.id}...`);
    setNotificationOpen(true);
    setActionState("Loading");

    try {
      await PlayerManagementDataService.ResendVerification();
      setNotificationVariant("success");
      setNotificationMessage(`Verification email sent to ${playerData.id}!`);
      setActionState("Success");
    }
    catch (e) {
      setNotificationVariant("error");
      setNotificationMessage("Failed to resend verification email!. Please report to administrator@overclockedgames.com");
      setActionState("Failed");
    }

    return actionState;
  };

  function onNotificationClose(): void {
    setNotificationOpen(false);
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              Actions
            </Typography>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body2">
          Various maintenance and account actions
        </Typography>
      </div>
      <Divider variant="middle" />
      <Container className={classes.bodyContainer}>
        {
          !playerData.verified && <Button
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<SendIcon />}
            onClick={onResendVerificationClick}
            disabled={actionState === "Loading"}
          >
            Resend Verification Email
          </Button>}
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          endIcon={<VpnKeyIcon />}
          disabled={actionState === "Loading"}
          onClick={onResetPasswordClick}
        >
          Reset Password
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          startIcon={<DeleteIcon />}
          disabled={actionState === "Loading"}
          onClick={onDeleteClick}
        >
          Delete Account
        </Button>
        {!playerData.verified && <div>
          <Typography color="textSecondary" variant="body1">
            Account not verified! Please check {playerData.id} for your verification email or click on the below above to resend verification
          </Typography>
        </div>
        }
      </Container>
      {notificationOpen ? <PopupNotification message={notificationMessage} duration={5000} variant={notificationVariant} isOpen={true} onClose={onNotificationClose} /> : null}
      <ConfirmationAlert headerText="Reset Password Confirmation" questionText="Are you sure you want to reset your password?" confirmText="Yes" closeText="No" isOpen={resetPassConfirmOpen} onConfirmClick={onResetPasswordConfirmClick} />
      <ConfirmationAlert headerText="Account Deletion Confirmation" questionText="Are you sure you want to delete your account?" confirmText="Yes" closeText="No" isOpen={deleteConfirmOpen} onConfirmClick={onDeleteConfirmClick} />
    </div>
  );
};

export default ProfileActions;