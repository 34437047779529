import { Card, Container, Grid, Paper, Typography } from "@mui/material";
import {TFunction} from "i18next";
import { i18n } from "i18next";
import React from "react";
import { PlayerData } from "../contracts/PlayerData";
import { PlayerManagementService } from "../common/services/PlayerManagementService";
import ProfileInfo from "./ProfileInfo";
import Inventory from "./Inventory";
import ProfileActions from "./ProfileActions";

export interface ProfileProps {
  i18n: i18n;
  t: TFunction;
}

export interface ProfileState {
  playerData: PlayerData | null;
}

export class Profile extends React.Component<ProfileProps, ProfileState> {

  constructor(props: ProfileProps) {
    super(props);
    const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
    this.state = {
      playerData: playerData
    };
  }

  public componentDidMount() {
    const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
    this.setState({playerData: playerData});
  }

  public render(): JSX.Element {

    // const defaultTheme = createMuiTheme();
    if (!this.state.playerData){
      return (
        <Container>
          <Card style={{padding: "100px"}}>
            <Typography>
              You have been signed out. Please sign in to see this page.
            </Typography>
          </Card>
        </Container>
      );
    }

    return (
      <React.Fragment>
        <Card>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} md={3}>
              <Inventory items={this.state.playerData.items} />
            </Grid>
            <Grid item xs={12} md={9}>
              <Paper style={{ padding: "10px" }}>
                <ProfileInfo i18n={this.props.i18n} t={this.props.t} />
                <ProfileActions />
              </Paper>
            </Grid>
          </Grid>
        </Card>
      </React.Fragment>
    );
  }
}