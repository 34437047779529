import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Stack } from "@mui/material";
import { Fade, Slide } from "react-awesome-reveal";

const Capture: React.FC = () => {

  const { t } = useTranslation();

  return (
    <Box className="content-wrapper capture-yokai-page">
      <Stack
        direction={{ xs: "column", sm: "column", md: "row" }}
        spacing={{ xs: 0, sm: 1, md: 2 }}
        alignItems="center"
      >
        <span className="capture-yokai-image image-wrapper" />
        <div className="text-wrapper">
          <Slide direction="right">
            <Fade cascade delay={200} duration={800}>
              <Typography style={{ color: "#444" }} component="h2">{t("home:capture.header")}</Typography>
            </Fade>
          </Slide>
          <Typography style={{ color: "#444" }} component="p">{t("home:capture.first-paragraph")}</Typography>
          <Typography style={{ color: "#444" }} component="p">{t("home:capture.second-paragraph")}</Typography>
        </div>
      </Stack>
    </Box>
  );
};

export default Capture;
