export class PlayerManagementService {

  public static readonly ProfileDataKey: string = "ProfileData";

  public static LooksLikeEmail(email: string): boolean {

    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }

  public static async GetRegistrationCheckFeedback(email: string): Promise<string | null> {

    let returnFeedback: string | null = null;

    if (!PlayerManagementService.LooksLikeEmail(email)) {
      returnFeedback = "Please input a valid email address";
    }

    return returnFeedback;
  }

  public static StoreInSession<T>(key: string, value: T): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public static ClearSession(): void {
    sessionStorage.clear();
  }

  public static GetFromSession<T>(key: string): T | null {
    const item: string | null = sessionStorage.getItem(key);
    let instance: T | null = null;
    if (item) {
      instance = JSON.parse(item as string);
    }
    return instance;
  }
}