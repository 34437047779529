import React from "react";
import { useTranslation } from "react-i18next";
// import { useTheme } from "@mui/material/styles";
import { Typography, Box, Stack } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { Fade } from "react-awesome-reveal";
// import useMediaQuery from "@mui/material/useMediaQuery";

export interface BannerComponentProps {
  onScrollToDetailsClick: () => void;
}

const Banner: React.FC<BannerComponentProps> = (props: BannerComponentProps) => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Box className="page banner-wrapper" id="banner">
        <Stack className={"banner-text-wrapper"}>
          <Fade cascade triggerOnce delay={500}>
            <Typography key="h2" component="h2">{t("home:banner.headline")}</Typography>
            <Typography key="content" component="p">{t("home:banner.subtitle")}</Typography>
            <span className="line" key="line" />
            <div key="button" className="start-button clearfix">
              <a onClick={props.onScrollToDetailsClick}>
                <ArrowDownwardIcon className="down" />
              </a>
            </div>
          </Fade>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default Banner;
