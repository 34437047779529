import React, { FunctionComponent } from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";


export interface ConfirmationAlertProps {
  headerText: string;
  questionText: string;
  confirmText: string;
  closeText: string;
  isOpen: boolean;
  onConfirmClick: (confirmed: boolean) => Promise<void>;
}

export const ConfirmationAlert: FunctionComponent<ConfirmationAlertProps> = (props: ConfirmationAlertProps) => {

  const handleClose = () => {
    props.onConfirmClick(false);
  };

  const handleConfirmClose = () => {
    props.onConfirmClick(true);
  };

  return (
    <div>
      <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.headerText}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.questionText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {props.closeText}
          </Button>
          <Button onClick={handleConfirmClose} color="primary" autoFocus>
            {props.confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationAlert;