import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import React, { FunctionComponent } from "react";
import { CardBody } from "reactstrap";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";

import coinCountingHeader from "../content/images/coin-counting.png";
import memoryHeader from "../content/images/memory-game.png";
import yokaiScrollsHeader from "../content/images/chrono-cover.png";

import { NavLink } from "react-router-dom";

export interface GamesProps {
  i18n: i18n;
  t: TFunction;
}

const styles = makeStyles()(() => {
  return {
    root: {
      maxWidth: 400,
    },
    media: {
      height: 300,
    },
    header: {
      backgroundColor: "#f1f1f1"
    }
  };
});

const Games: FunctionComponent<GamesProps> = (props: GamesProps) => {
  const { classes } = styles();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div style={{ width: "100%" }}>
            <Box
              display="flex"
              flexWrap="wrap"
              p={1}
              m={1}
              bgcolor="background.paper"
              justifyContent="center"
              sx={{ maxWidth: 1600 }}
            >
              <Box p={1}>
                <NavLink to="/games/coin-counting">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("games:games-list.coin-counting.title")} />
                      <CardMedia
                        className={classes.media}
                        image={coinCountingHeader}
                        title={props.t("games:games-list.coin-counting.title")}
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {props.t("games:games-list.coin-counting.description")}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </NavLink>
              </Box>
              <Box p={1}>
                <NavLink to="/games/memory">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("games:games-list.memory.title")} />
                      <CardMedia
                        className={classes.media}
                        image={memoryHeader}
                        title={props.t("games:games-list.memory.title")}
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {props.t("games:games-list.memory.description")}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </NavLink>
              </Box>
              <Box p={1}>
                <NavLink to="/games/yokai-scrolls">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("games:games-list.yokai-scrolls.title")} />
                      <CardMedia
                        className={classes.media}
                        image={yokaiScrollsHeader}
                        title={props.t("games:games-list.yokai-scrolls.title")}
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {props.t("games:games-list.yokai-scrolls.description")}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </NavLink>
              </Box>
            </Box>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Games;
