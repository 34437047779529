import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import explore_en from "./content/translations/en/explore.json";
import explore_jp from "./content/translations/jp/explore.json";
import header_en from "./content/translations/en/Header.json";
import header_jp from "./content/translations/jp/Header.json";
import accountflowmodal_en from "./content/translations/en/AccountFlowModal.json";
import accountflowmodal_jp from "./content/translations/jp/AccountFlowModal.json";
import items_en from "./content/translations/en/Items.json";
import items_jp from "./content/translations/jp/Items.json";
import profile_en from "./content/translations/en/Profile.json";
import profile_jp from "./content/translations/jp/Profile.json";
import home_en from "./content/translations/en/home.json";
import home_jp from "./content/translations/jp/home.json";
import games_en from "./content/translations/en/games.json";
import games_jp from "./content/translations/jp/games.json";
import support_en from "./content/translations/en/support.json";
import support_jp from "./content/translations/jp/support.json";
import study_en from "./content/translations/en/study.json";
import study_jp from "./content/translations/jp/study.json";
import yokai_scrolls_en from "./content/translations/en/yokai-scrolls.json";
import yokai_scrolls_jp from "./content/translations/jp/yokai-scrolls.json";

i18n
  .use(initReactI18next)
  .init(
    {
      // React already does escaping
      interpolation: { escapeValue: false },
      lng: "en",
      resources: {
        en: {
          "home": home_en,
          "explore": explore_en,
          "header": header_en,
          "accountflowmodal": accountflowmodal_en,
          "items": items_en,
          "profile": profile_en,
          "games": games_en,
          "support": support_en,
          "study": study_en,
          "yokai-scrolls": yokai_scrolls_en
        },
        jp: {
          "home": home_jp,
          "explore": explore_jp,
          "header": header_jp,
          "accountflowmodal": accountflowmodal_jp,
          "items": items_jp,
          "profile": profile_jp,
          "games": games_jp,
          "support": support_jp,
          "study": study_jp,
          "yokai-scrolls": yokai_scrolls_jp
        }
      }
    });

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <HashRouter>
      <App />
    </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
