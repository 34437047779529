import React, { FunctionComponent } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import Snackbar from "@mui/material/Snackbar";
import WarningIcon from "@mui/icons-material/Warning";

import Stack from "@mui/material/Stack";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const VariantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

export interface PopupNotificationContentProps {
  className?: string;
  message?: string;
  onClose?: () => void;
  variant: keyof typeof VariantIcon;
}

export interface PopupNotificationProps {
  message?: string;
  variant: keyof typeof VariantIcon;
  duration: number;
  isOpen: boolean;
  onClose?: () => void;
}

export const PopupNotification: FunctionComponent<PopupNotificationProps> = (props: PopupNotificationProps) => {
  const [open, setOpen] = React.useState(props.isOpen);

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    if (props.onClose) {
      props.onClose();
    }

    setOpen(false);
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar open={open} autoHideDuration={props.duration} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.variant} sx={{ width: "100%" }}>
          {props.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default PopupNotification;