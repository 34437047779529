import { TFunction } from "i18next";
import { i18n } from "i18next";
import { Box, Button, LinearProgress, LinearProgressProps, Stack, Typography } from "@mui/material";
import React, { FunctionComponent, useCallback, useState } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import useWindowDimensions from "../common/hooks/GetWindowDimensions";
import { PlayerManagementService } from "../common/services/PlayerManagementService";
import { PlayerData } from "../contracts/PlayerData";

export interface memoryProps {
  i18n: i18n;
  t: TFunction;
}

function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const Memory: FunctionComponent<memoryProps> = (props: memoryProps) => {
  const { height, width } = useWindowDimensions();
  const usableHeight: number = height * .74;

  const { unityProvider, isLoaded, loadingProgression, requestFullscreen } = useUnityContext({
    loaderUrl: "https://overclockedgames.blob.core.windows.net/public/webgl/memory/Memory.loader.js",
    dataUrl: "https://overclockedgames.blob.core.windows.net/public/webgl/memory/Memory.data.br",
    frameworkUrl: "https://overclockedgames.blob.core.windows.net/public/webgl/memory/Memory.framework.js.br",
    codeUrl: "https://overclockedgames.blob.core.windows.net/public/webgl/memory/Memory.wasm.br",
  });

  // Round the loading progression to a whole number to represent the
  // percentage of the Unity Application that has loaded.
  const loadingPercentage = Math.round(loadingProgression * 100);

  // Use a state to store the device pixel ratio.
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  useCallback(
    function () {
      // A function which will update the device pixel ratio of the Unity
      // application to match the device pixel ratio of the browser.
      const updateDevicePixelRatio = function () {
        setDevicePixelRatio(window.devicePixelRatio);
      };
      // A media matcher which watches for changes in the device pixel ratio.
      const mediaMatcher = window.matchMedia(
        `screen and (resolution: ${devicePixelRatio}dppx)`
      );
      // Adding an event listener to the media matcher which will update the
      // device pixel ratio of the Unity Application when the device pixel
      // ratio changes.
      mediaMatcher.addEventListener("change", updateDevicePixelRatio);
      return function () {
        // Removing the event listener when the component unmounts.
        mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
      };
    },
    [devicePixelRatio]
  );

  function handleClickEnterFullscreen() {
    requestFullscreen(true);
  }

  const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
  let hasGame = false;
  if (null != playerData) {
    for (let i = 0; i < playerData.games.length; ++i) {
      if (playerData.games[i].gameId == "Memory") {
        hasGame = true;
      }
    }
  }

  return (
    <div style={{ width: "100%" }}>
      {hasGame == false ? (
        <Box sx={{ width: "100%" }}
          display="flex"
          flexWrap="wrap"
          p={5}
          justifyContent="center"
        >
          <Typography>
            <div
              dangerouslySetInnerHTML={{ __html: null == playerData ? props.t("games:game-page.log-in-required") : props.t("games:games-list.coin-counting.invite-message", { interpolation: { escapeValue: false } }) }} />
          </Typography>
        </Box>
      ) : <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center">
        {isLoaded === false && (
          // Conditionally render the loading overlay if the Unity application is not loaded.
          <Box sx={{ width: "100%" }}>
            <LinearProgressWithLabel value={loadingPercentage} />
          </Box>
        )}
        <Stack>
          <Button onClick={handleClickEnterFullscreen}><Typography>{props.t("games:game-page.fullscreen")}</Typography></Button>
          <Unity
            unityProvider={unityProvider}
            style={{ width: width, height: usableHeight }}
            devicePixelRatio={devicePixelRatio}
          />
        </Stack>
      </Box>
      }
    </div>
  );
};

export default Memory;
