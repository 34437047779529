import { Grid, Paper, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import React, { FunctionComponent } from "react";
import { PlayerManagementService } from "../common/services/PlayerManagementService";
import { PlayerData } from "../contracts/PlayerData";

import pcDownloadImage from "../content/images/pc-download.png";
import linuxDownloadImage from "../content/images/linux-download.png";
import macDownloadImage from "../content/images/mac-download.png";

export interface YokaiScrollsProps {
  i18n: i18n;
  t: TFunction;
}

const YokaiScrolls: FunctionComponent<YokaiScrollsProps> = (props: YokaiScrollsProps) => {

  const playerData: PlayerData | null = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey);
  let hasGame = false;
  if (null != playerData) {
    for (let i = 0; i < playerData.games.length; ++i) {
      if (playerData.games[i].gameId == "Yokai Scrolls") {
        hasGame = true;
      }
    }
  }

  return (
    <div>
      {hasGame == false ? (
        <div>
          <Typography align="center" sx={{
            paddingTop: 10,
            paddingBottom: 10,
          }}>
          Support us on <a href="https://ko-fi.com/yokaiscrolls/tiers" target="_blank" rel="noreferrer">Kofi</a> to gain access to our alpha build
          </Typography>
        </div>
      ) : (
        <React.Fragment>
          <Grid item xs={12} sx={{
            paddingTop: 10,
            paddingBottom: 10,
          }}>
            <Typography align="center">{props.t("yokai-scrolls:supporter.thanks")}</Typography>

            <Grid container justifyContent="center" spacing={10} paddingTop={5}>              
              <Grid key={0} item>
                <a href="https://overclockedgames.blob.core.windows.net/public/launchers/yokai-scrolls/win-x64/yokai-scrolls-launcher[0.1.0.0].zip">
                  <Paper elevation={0} sx={{
                    height: 200,
                    width: 200,
                    backgroundImage: `url(${pcDownloadImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}>
                  </Paper>
                </a>
              </Grid>
              <Grid key={1} item>
                <a href="https://overclockedgames.blob.core.windows.net/public/launchers/yokai-scrolls/linux-x64/yokai-scrolls-launcher[0.1.0.0].zip">
                  <Paper elevation={0} sx={{
                    height: 200,
                    width: 200,
                    backgroundImage: `url(${linuxDownloadImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}>
                  </Paper>
                </a>
              </Grid>

              <Grid key={2} item>
                <a href="https://overclockedgames.blob.core.windows.net/public/launchers/yokai-scrolls/osx-x64/yokai-scrolls-launcher[0.1.0.0].zip">
                  <Paper elevation={0} sx={{
                    height: 200,
                    width: 200,
                    backgroundImage: `url(${macDownloadImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}>
                  </Paper>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>)
      }
    </div>
  );
};

export default YokaiScrolls;
