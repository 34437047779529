import { Card, CardActionArea, CardHeader, CardMedia } from "@mui/material";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import React, { FunctionComponent } from "react";
import { CardBody } from "reactstrap";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";

import swagImage from "../content/images/swag.jpg";
import koFiImage from "../content/images/ko-fi_white_stroke.png";

export interface supportProps {
  i18n: i18n;
  t: TFunction;
}

const characterDescStyles = makeStyles()(() => {
  return {
    root: {
      maxWidth: 400,
    },
    media: {
      height: 300,
    },
    header: {
      backgroundColor: "#f1f1f1"
    }
  };
});

const Support: FunctionComponent<supportProps> = (props: supportProps) => {

  const { classes } = characterDescStyles();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div style={{ width: "100%" }}>
            <Box
              display="flex"
              flexWrap="wrap"
              p={1}
              m={1}
              bgcolor="background.paper"
              justifyContent="center"
              sx={{ maxWidth: 1600 }}
            >
              <Box p={1}>
                <a href="https://www.redbubble.com/people/ocgames/shop" target="_blank" rel="noopener noreferrer">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("support:support-list.red-bubble.title")}
                        subheader={props.t("support:support-list.red-bubble.description")} />
                      <CardMedia
                        className={classes.media}
                        image={swagImage}
                        title={props.t("support:support-list.red-bubble.title")}
                      />
                    </CardActionArea>
                  </Card>
                </a>
              </Box>
              <Box p={1}>
                <a href="https://ko-fi.com/yokaiscrolls" target="_blank" rel="noopener noreferrer">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("support:support-list.ko-hi.title")}
                        subheader={props.t("support:support-list.ko-hi.description")} />
                      <CardMedia
                        className={classes.media}
                        image={koFiImage}
                        title={props.t("support:support-list.ko-hi.title")}
                      />
                    </CardActionArea>
                  </Card>
                </a>
              </Box>
            </Box>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Support;
