import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Stack } from "@mui/material";
import { Fade, Slide } from "react-awesome-reveal";

export interface PlatformsComponentProps {
  innerRef: React.RefObject<HTMLDivElement>;
}

const Platforms: React.FC<PlatformsComponentProps> = (props: PlatformsComponentProps) => {
  
  const { t } = useTranslation();

  return (
    <Box ref={props.innerRef} className="content-wrapper platforms-page">
      <Stack
        spacing={{ xs: 0, sm: 0, md: 0 }}
        alignItems="center"
      >
        <span className="banner-platforms-image image-wrapper" />
        <div className="text-wrapper-center">
          <Slide direction="right">
            <Fade cascade delay={200} duration={800}>
              <Typography component="h1">{t("home:platforms.header")}</Typography>
            </Fade>
          </Slide>
        </div>
      </Stack>
    </Box>
  );
};

export default Platforms;