import { Card, CardActionArea, CardContent, CardHeader, CardMedia, Paper, Typography, Stack } from "@mui/material";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import React, { FunctionComponent } from "react";
import { CardBody } from "reactstrap";
import { makeStyles } from "tss-react/mui";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

import coinCountingHeader from "../content/images/coin-counting.png";
import memoryHeader from "../content/images/memory-game.png";

import { NavLink } from "react-router-dom";

export interface StudyProps {
  i18n: i18n;
  t: TFunction;
}

const styles = makeStyles()(() => {
  return {
    root: {
      maxWidth: 400,
    },
    media: {
      height: 300,
    },
    header: {
      backgroundColor: "#f1f1f1"
    }
  };
});

const Study: FunctionComponent<StudyProps> = (props: StudyProps) => {
  const { classes } = styles();

  const SectionHeader = styled(Paper)(({ theme }) => ({
    textAlign: "center",
    color: theme.palette.text.secondary,
    padding: 30
  }));

  return (
    <React.Fragment>
      <div>
        <Stack
          alignItems="center">
          <SectionHeader variant="outlined" elevation={20} square>
            <Typography style={{ color: "#444" }} component="h1">{props.t("study:sections.games.title")}</Typography>
            <Typography component="p">{props.t("study:sections.games.description")}</Typography>
          </SectionHeader>
          <CardBody>
            <div style={{ width: "100%" }}>
              <Box
                display="flex"
                flexWrap="wrap"
                p={1}
                m={1}
                bgcolor="background.paper"
                justifyContent="center"
                sx={{ maxWidth: 1600 }}
              >
                <Box p={1}>
                  <NavLink to="/games/coin-counting">
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardHeader
                          className={classes.header}
                          title={props.t("games:games-list.coin-counting.title")} />
                        <CardMedia
                          className={classes.media}
                          image={coinCountingHeader}
                          title={props.t("games:games-list.coin-counting.title")}
                        />
                        <CardContent>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {props.t("games:games-list.coin-counting.description")}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </NavLink>
                </Box>
                <Box p={1}>
                  <NavLink to="/games/memory">
                    <Card className={classes.root}>
                      <CardActionArea>
                        <CardHeader
                          className={classes.header}
                          title={props.t("games:games-list.memory.title")} />
                        <CardMedia
                          className={classes.media}
                          image={memoryHeader}
                          title={props.t("games:games-list.memory.title")}
                        />
                        <CardContent>
                          <Typography variant="body2" color="textSecondary" component="p">
                            {props.t("games:games-list.memory.description")}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </NavLink>
                </Box>
              </Box>
            </div>
          </CardBody>
        </Stack>
      </div>
    </React.Fragment >
  );
};

export default Study;
