import React from "react";
import { useTranslation } from "react-i18next";
import { Typography, Box, Stack, Paper } from "@mui/material";
import { Fade, Slide } from "react-awesome-reveal";
import Grid from "@mui/material/Unstable_Grid2";
import { SvgIcon } from "@mui/material";

import { ReactComponent as RedditIcon } from "../content/images/reddit.svg";
import { ReactComponent as FacebookIcon } from "../content/images/facebook.svg";
import { ReactComponent as TwitterIcon } from "../content/images/twitter.svg";
import { ReactComponent as InstagramIcon } from "../content/images/instagram.svg";

const Community: React.FC = () => {

  const { t } = useTranslation();

  return (
    <Box className="content-wrapper community-page">
      <Stack>
        <Grid container spacing={{ xs: 0, sm: 1, md: 2 }}>
          <Grid xs={6}>
            <Box>
              <div>
                <link href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css" rel="stylesheet" type="text/css"></link>
                <div id="mc_embed_signup">
                  <form action="https://yokaiscrolls.us13.list-manage.com/subscribe/post?u=f73d1a4f38f7e4b231daed856&amp;id=5cef08509a" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
                    <Stack>
                      <Fade cascade
                        className="text-wrapper"
                        key="text">
                        <Typography key="h2" component="h2">{t("home:community.header")}</Typography>
                        <Typography component="p">{t("home:community.mail-list-text")}</Typography>
                      </Fade>
                      <div id="mc_embed_signup_scroll">
                        <input type="email" name="EMAIL" className="email" id="mce-EMAIL" placeholder={t("home:community.mail-list-placeholder-text") as string | undefined } required />
                        <div style={{ "position": "absolute", "left": "-5000px" }}>
                          <input type="text" name="b_f73d1a4f38f7e4b231daed856_5cef08509a" defaultValue="" />
                        </div>
                        <div className="clear">
                          <input type="submit" value={t("home:community.mail-list-button-text") as string | undefined } name="subscribe" id="mc-embedded-subscribe" className="button" />
                        </div>
                      </div>
                    </Stack>
                  </form>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid xs={6}>
            <Slide direction="right">
              <Fade cascade delay={300} duration={1000}>
                <Box>
                  <span className="community-image image-wrapper" />
                </Box>
              </Fade>
            </Slide>
          </Grid>
          <Grid xs={12} md={6}>
            <Paper elevation={2} style={{ padding: "2%", margin: "5%" }}>
              <a href="https://www.instagram.com/yokaiscrolls/" target="_blank" rel="noreferrer"> <Typography component="h2"><SvgIcon sx={{ color: "red" }} fontSize="large" component={InstagramIcon} inheritViewBox /> {t("home:community.instagram-text")}</Typography></a>
            </Paper>
            <Paper elevation={2} style={{ padding: "2%", margin: "5%" }}>
              <a href="https://www.reddit.com/r/yokaiscrolls/" target="_blank" rel="noreferrer"> <Typography component="h2"><SvgIcon sx={{ color: "red" }} fontSize="large" component={RedditIcon} inheritViewBox /> {t("home:community.reddit-text")}</Typography></a>
            </Paper>
            <Paper elevation={2} style={{ padding: "2%", margin: "5%" }}>
              <a href="https://www.facebook.com/yokaiscrolls" target="_blank" rel="noreferrer"> <Typography component="h2"><SvgIcon sx={{ color: "red" }} fontSize="large" component={FacebookIcon} inheritViewBox /> {t("home:community.facebook-text")}</Typography></a>
            </Paper>
            <Paper elevation={2} style={{ padding: "2%", margin: "5%" }}>
              <a href="https://twitter.com/yokaiscrolls" target="_blank" rel="noreferrer"> <Typography component="h2"><SvgIcon sx={{ color: "red" }} fontSize="large" component={TwitterIcon} inheritViewBox /> {t("home:community.twitter-text")}</Typography></a>
            </Paper>
          </Grid>
          <Grid xs={12} md={6}>
            <Stack>
              <Paper elevation={0} style={{ padding: "5%" }}>
                <div className="text-wrapper">
                  <Typography component="p"> {t("home:community.discord-text")}</Typography>
                </div>
              </Paper>
              <Box>
                <iframe src="https://discord.com/widget?id=1001023380130107432&theme=light" width="350" height="500" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
};

export default Community;
