import React from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink, LinkProps as RouterLinkProps, Routes, Route } from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import Home from "./home/Home";
import Explore from "./explore/Explore";
import Games from "./games/Games";
import YokaiBook from "./explore/YokaiBook";
import { HeaderComponent } from "./Header";
import Footer from "./Footer";
import { Profile } from "./user/Profile";
import CoinCounting from "./games/CoinCounting";
import Memory from "./games/Memory";
import Support from "./support/Support";
import Study from "./study/Study";


import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import YokaiScrolls from "./games/YokaiScrolls";


const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});
LinkBehavior.displayName = "LinkBehavior";

const theme = createTheme({
  typography: {
    "fontFamily": "'DFKaiSB', 'Roboto', 'Arial', sans-serif",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  }
});

const App: React.FC = () => {

  const { t, i18n } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <HeaderComponent key="header" i18n={i18n} t={t} />
      <Routes>
        <Route path="/" element={<Home i18n={i18n} t={t} />} />
        <Route path="/explore" element={<Explore i18n={i18n} t={t} />} />
        <Route path="/explore/yokai" element={<YokaiBook />} />
        <Route path="/profile" element={<Profile i18n={i18n} t={t} />} />
        <Route path="/games" element={<Games i18n={i18n} t={t} />} />
        <Route path="/games/coin-counting" element={<CoinCounting i18n={i18n} t={t} />} />
        <Route path="/games/memory" element={<Memory i18n={i18n} t={t} />} />
        <Route path="/games/yokai-scrolls" element={<YokaiScrolls i18n={i18n} t={t} />} />
        <Route path="/support" element={<Support i18n={i18n} t={t} />} />
        <Route path="/study" element={<Study i18n={i18n} t={t} />} />
      </Routes>
      <Footer key="footer" />,
    </ThemeProvider>
  );
};

export default App;
