import React, { FunctionComponent } from "react";
import clsx from "clsx";
import { makeStyles } from "tss-react/mui";
import CircularProgress from "@mui/material/CircularProgress";
import { green, red } from "@mui/material/colors";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

export type ActionState = ("Idle" | "Loading" | "Success" | "Failed");

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      padding: "10px"
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    buttonFailure: {
      backgroundColor: red[500],
      "&:hover": {
        backgroundColor: red[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  };
});

export interface ActionButtonProps {
  icon: JSX.Element;
  onClick: () => Promise<ActionState>;
  passClearStateFunction?: (clearFunction: () => void) => void;
  disabled?: boolean;
}

export const ActionButton: FunctionComponent<ActionButtonProps> = (props: ActionButtonProps) => {
  const { classes } = useStyles();
  const [actionState, setActionState] = React.useState<ActionState>("Idle");
  const timer = React.useRef<number>();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: actionState === "Success",
    [classes.buttonFailure]: actionState === "Failed"
  });

  React.useEffect(() => {
    const timerValue = timer.current;
    return () => {
      clearTimeout(timerValue);
    };
  }, []);

  const handleButtonClick = async () => {
    if (actionState !== "Loading" && !props.disabled) {
      setActionState("Loading");
      const resultedState: ActionState = await props.onClick();
      setActionState(resultedState);
    }
  };

  const clearState = (override?: ActionState | undefined) => {
    if (undefined !== override) {
      setActionState(override);
    }
    else {
      setActionState("Idle");
    }
  };

  if (undefined !== props.passClearStateFunction)
  {
    props.passClearStateFunction(clearState);
  }

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Fab
          disabled={props.disabled}
          aria-label="save"
          color="primary"
          className={buttonClassname}
          onClick={handleButtonClick}
        >
          {actionState === "Idle" && props.icon}
          {actionState === "Success" && <CheckIcon />}
          {actionState === "Failed" && <ErrorIcon />}
        </Fab>
        {actionState === "Loading" && <CircularProgress size={68} className={classes.fabProgress} />}
      </div>
    </div>
  );
};

export default ActionButton;