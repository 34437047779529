import React from "react";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import Banner from "./Banner";
import World from "./World";
import Learn from "./Learn";
import Capture from "./Capture";
import Story from "./Story";
import Community from "./Community";
import "../static/style";
import Platforms from "./Platforms";
import Characters from "./Characters";
import Combat from "./Combat";
import Defend from "./Defend";
import Craft from "./Craft";
import Art from "./Art";

export interface HomeComponentProps {
  i18n: i18n;
  t: TFunction
}

export interface HomeComponentState {
  isFirstScreen: boolean;
}

class Home extends React.PureComponent<HomeComponentProps, HomeComponentState> {

  private detailsScrollRef: React.RefObject<HTMLDivElement>;

  constructor(props: HomeComponentProps) {
    super(props);
    this.state = {
      isFirstScreen: true,
    };

    this.detailsScrollRef = React.createRef();
  }

  private onScrollToDetailsClick() {
    if (this.detailsScrollRef) {
      const element = this.detailsScrollRef.current;
      if (element) {
        element.scrollIntoView();
      }
    }
  }

  onEnterChange = (mode: string) => {
    this.setState({
      isFirstScreen: mode === "enter",
    });
  };

  render() {
    return (
      [
        <Banner key="banner" onScrollToDetailsClick={() => { this.onScrollToDetailsClick(); }} />,
        <Platforms key="platforms" innerRef={this.detailsScrollRef} />,
        <World key="world" />,
        <Art key="art" />,
        <Story key="story" />,
        <Combat key="combat" />,
        <Characters key="characters" />,
        <Craft key="craft" />,
        <Defend key="defend" />,
        <Learn key="learn" />,
        <Capture key="capture" />,
        <Community key="community" />,
      ]
    );
  }
}
export default Home;
