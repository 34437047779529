import React, { useState, FunctionComponent } from "react";
import { i18n, TFunction } from "i18next";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { PlayerData } from "../contracts/PlayerData";
import { Slider, TextField, FormControl, InputLabel } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { PlayerManagementDataService } from "../common/services/PlayerManagementDataService";
import { PlayerManagementService } from "../common/services/PlayerManagementService";
import { PopupNotification, VariantIcon } from "../common/PopupNotification";
import { ActionButton, ActionState } from "../common/ActionButton";

export interface ProfileInfoComponentProps {
  i18n: i18n;
  t: TFunction;
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    formContainer: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: "20px"
    },
    slider: {
      width: "200px",
      margin: "20px",
      paddingTop: "25px"
    },
    header: {
      margin: theme.spacing(3, 2)
    },
    formControl: {
      marginLeft: theme.spacing(1),
      margingRight: theme.spacing(1)
    },
    saveButton: {
      float: "right"
    }
  };
});

const JlptLevels = [
  {
    value: 5,
    label: "N5"
  },
  {
    value: 4,
    label: "N4"
  },
  {
    value: 3,
    label: "N3"
  },
  {
    value: 2,
    label: "N2"
  },
  {
    value: 1,
    label: "N1"
  },
];

function JlptValueText(value: number): string {
  return `N${value}`;
}

export const ProfileInfo: FunctionComponent<ProfileInfoComponentProps> = (props: ProfileInfoComponentProps) => {
  const { classes } = useStyles();
  const playerData: PlayerData = PlayerManagementService.GetFromSession(PlayerManagementService.ProfileDataKey) as PlayerData;

  const [inputPlayerData, setInputPlayerData] = useState<PlayerData>(playerData);
  const [dataChanged, setDataChanged] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationVariant, setNotificationVariant] = useState<keyof typeof VariantIcon>("success");
  const [notificationOpen, setNotificationOpen] = useState(false);

  const handleTextFieldChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setDataChanged(true);
    setInputPlayerData({ ...inputPlayerData, [name]: event.target.value });
  };

  const handleSliderChange = (event: React.SyntheticEvent | Event, newValue: number | Array<number>) => {
    setDataChanged(true);
    setInputPlayerData({ ...inputPlayerData, "jlptLevel": newValue as number });
  };

  const onSaveClick = async (): Promise<ActionState> => {

    let actionState: ActionState = "Idle";
    try {
      await PlayerManagementDataService.UpdatePlayerData(inputPlayerData);
      setNotificationVariant("success");
      setNotificationMessage("Update Successful!");
      PlayerManagementService.StoreInSession(PlayerManagementService.ProfileDataKey, inputPlayerData);
      setDataChanged(false);
      actionState = "Success";
    }
    catch (e) {
      setNotificationVariant("error");
      setNotificationMessage("Update Failed!");
      actionState = "Failed";
    }
    setNotificationOpen(true);
    return actionState;
  };

  function onNotificationClose(): void {
    setNotificationOpen(false);
  }

  const verificationStatus: string = (playerData.verified) ? "verified" : "unverified";

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              Profile Information
            </Typography>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body2">
          View / edit your settings and player details from here
        </Typography>
      </div>
      <Divider variant="middle" />
      <form className={classes.formContainer} noValidate autoComplete="off">
        <TextField id="Email" label={`${props.t("profile:info.email")} (${verificationStatus})`} className={classes.formControl} value={inputPlayerData.id} onChange={handleTextFieldChange("id")} error={!playerData.verified} margin="normal" />
        <TextField id="UserName" label={props.t("profile:info.username")} className={classes.formControl} value={inputPlayerData.userName} onChange={handleTextFieldChange("UserName")} margin="normal" />
        <TextField id="StudyHourDailyGoal" label={props.t("profile:info.studyHourDailyGoal")} className={classes.formControl} value={inputPlayerData.studyHourDailyGoal} onChange={handleTextFieldChange("StudyHourDailyGoal")} margin="normal" />
        <TextField id="ExperiencePoints" label={props.t("profile:info.experiencePoints")} className={classes.formControl} value={inputPlayerData.experiencePoints} margin="normal" disabled />
        <TextField id="Zeni" label={`${props.t("profile:info.zeni")}(¥):`} className={classes.formControl} value={inputPlayerData.zeni} margin="normal" disabled />
        <FormControl className={classes.formControl}>
          <InputLabel htmlFor="jlpt-slider">{props.t("profile:info:jlptSet")}</InputLabel>
          <div className={classes.slider}>
            {/** Add track="inverted" to Slider after that"s checked in: https://github.com/mui-org/material-ui-issues/17215 */}
            <Slider defaultValue={inputPlayerData.jlptLevel} getAriaValueText={JlptValueText} aria-labelledby="jlpt-slider" id="jlpt-slider" step={null} marks={JlptLevels} min={1} max={5} onChangeCommitted={handleSliderChange} />
          </div>
        </FormControl>

      </form>
      <div className={classes.saveButton}>
        <ActionButton icon={<SaveIcon />} onClick={onSaveClick} disabled={!dataChanged} />
      </div>
      {notificationOpen ? <PopupNotification message={notificationMessage} duration={5000} variant={notificationVariant} isOpen={true} onClose={onNotificationClose} /> : null}
    </div>
  );
};

export default ProfileInfo;