import { RegisterModel } from "../../contracts/RegisterModel";
import { LoginModel } from "../../contracts/LoginModel";
import { PasswordlessLoginModel } from "../../contracts/PasswordlessLoginModel";
import { PlayerData } from "../../contracts/PlayerData";

export type MethodType = ("POST" | "GET" | "PATCH" | "DELETE");

export class PlayerManagementDataService {

  public static async RegisterNewUser(email: string, password: string): Promise<void> {

    const data: RegisterModel = {
      UserName: email.substr(0, email.indexOf("@")),
      Email: email,
      Password: password
    };

    const reqInit: RequestInit = this.InitializeRequest(data, "POST", false);
    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/create`, reqInit);
    await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async Login(email: string, password: string): Promise<void> {

    const data: LoginModel = {
      email: email,
      password: password
    };

    const reqInit: RequestInit = this.InitializeRequest(data, "POST", true);
    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/login`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async StartPasswordlessEmailLogin(email: string): Promise<void> {

    const reqInit: RequestInit = this.InitializeRequest(null, "POST", false);
    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/request/code/email?email=${email}`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async CompletePasswordlessEmailLogin(email: string, verificationCode: string): Promise<void> {

    const data: PasswordlessLoginModel = {
      email: email,
      verificationCode: verificationCode
    };

    const reqInit: RequestInit = this.InitializeRequest(data, "POST", false);
    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/login/code`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async GetUserData(): Promise<PlayerData> {

    const reqInit: RequestInit = this.InitializeRequest(null, "GET", true);
    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/data`, reqInit);
    await this.CheckForSuccessfulResponse(response, 200);

    const playerData: PlayerData = await response.json();
    return playerData;
  }

  public static async UpdatePlayerData(playerData: PlayerData): Promise<void> {

    const reqInit: RequestInit = this.InitializeRequest(playerData, "PATCH", true);

    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/update`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async ResetPassword(email: string): Promise<void> {

    const reqInit: RequestInit = this.InitializeRequest(email, "POST", false);

    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/password/reset`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async ResendVerification(): Promise<void> {

    const reqInit: RequestInit = this.InitializeRequest(null, "POST", true);

    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/verify`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  public static async DeleteAccount(): Promise<void> {

    const reqInit: RequestInit = this.InitializeRequest(null, "DELETE", true);

    const response: Response = await fetch(`${process.env.REACT_APP_OCGSERVICE_API_ROOT}/users/v1/web/delete`, reqInit);
    return await this.CheckForSuccessfulResponse(response, 200);
  }

  /**
   * Helper method to check for success codes and fail if we don't find what we're looking for. Useful for fetch since it doesn't
   * throw on unsuccesseful status codes like ajax does
   */
  private static async CheckForSuccessfulResponse(response: Response, statusCode: number) {
    if (response.status !== statusCode) {
      const failureReason: string = await response.text();
      const exception: HttpException = {
        Message: response.status == 404 ? "Login information doesn't match our records. Please double-check your email address and/or password" : `${response.statusText}, Code: ${response.status}`,
        StatusCode: response.status,
        ErrorStack: failureReason
      };
      
      throw exception;
    }
  }

  /**
   * Helper method to initalize requests. If includeCredentials is true, this will include the HTTPOnly authentication token originally set 
   * by OCGService's Login endpoint in the request
   */
  private static InitializeRequest<T>(data: T, method: MethodType, includeCredentials: boolean): RequestInit {
    return {
      method: method,
      mode: "cors",
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/json"
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      credentials: (includeCredentials) ? "include" : "omit",
      body: (data) ? JSON.stringify(data) : null,
    };
  }
}

export interface HttpException {
  Message: string;
  StatusCode: number;
  ErrorStack: string;
}