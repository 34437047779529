import { Card, Typography } from "@mui/material";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import React, { FunctionComponent } from "react";
import { PlayerData } from "../contracts/PlayerData";
import { CardBody } from "reactstrap";
import { makeStyles } from "tss-react/mui";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import { NavLink } from "react-router-dom";
import Box from "@mui/material/Box";

import yokaiHeaderImage from "../content/images/YokaiImageHeader.jpg";
import devBlogHeaderImage from "../content/images/noun_game dev_3346557.png";

export interface exploreProps {
  i18n: i18n;
  t: TFunction;
}

export interface exploreState {
  playerData: PlayerData | null;
}

const characterDescStyles = makeStyles()(() => {
  return {
    root: {
      maxWidth: 400,
    },
    media: {
      height: 300,
    },
    header: {
      backgroundColor: "#f1f1f1"
    }
  };
});

const Explore: FunctionComponent<exploreProps> = (props: exploreProps) => {

  const { classes } = characterDescStyles();

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div style={{ width: "100%" }}>
            <Box
              display="flex"
              flexWrap="wrap"
              p={1}
              m={1}
              bgcolor="background.paper"
              justifyContent="center"
              sx={{ maxWidth: 1600 }}
            >
              <Box p={1}>
                <NavLink to="/explore/yokai">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("explore:options.yokai.title")}
                        subheader={props.t("explore:options.yokai.summary")} />
                      <CardMedia
                        className={classes.media}
                        image={yokaiHeaderImage}
                        title={props.t("explore:options.yokai.title")}
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {props.t("explore:options.yokai.description")}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </NavLink>
              </Box>
              <Box p={1}>
                <a href="https://blog.yokaiscrolls.com">
                  <Card className={classes.root}>
                    <CardActionArea>
                      <CardHeader
                        className={classes.header}
                        title={props.t("explore:options.devblog.title")}
                        subheader={props.t("explore:options.devblog.summary")} />
                      <CardMedia
                        className={classes.media}
                        image={devBlogHeaderImage}
                        title={props.t("explore:options.devblog.title")}
                      />
                      <CardContent>
                        <Typography variant="body2" color="textSecondary" component="p">
                          {props.t("explore:options.devblog.description")}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </a>
              </Box>
            </Box>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default Explore;
