import React, { useState, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";

import Carousel from "../common/Carousel";
import rokurokubiImagesByIndex, {rokurokubiImages, rokurokubiAltByIndex} from "./yokai/RokurokubiImages";

import akanameImage from "../content/images/yokai/Akaname.png";
import biwaBokuBokuImage from "../content/images/yokai/BiwaBokuBoku.png";
import jikininkiImage from "../content/images/yokai/Jikininki.png";
import kamaItachiImage from "../content/images/yokai/KamaItachi.png";
import kappaImage from "../content/images/yokai/Kappa.png";
import karaKasaImage from "../content/images/yokai/KaraKasa.png";
import namahageImage from "../content/images/yokai/Namahage.png";
import nurikabeImage from "../content/images/yokai/Nurikabe.png";
import tenguImage from "../content/images/yokai/Tengu.png";
import yanariImage from "../content/images/yokai/Yanari.png";
import zashikiWarashiImage from "../content/images/yokai/ZashikiWarashi.png";

export interface TabPanelProps {
  children: PropTypes.ReactElementLike;
  node?: PropTypes.Requireable<PropTypes.ReactNodeLike>;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,

    },
    tabs: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    img: {
      maxWidth: "600px",
      "@media (max-width:450px)": {
        maxWidth: "300px",
      }
    }
  };
});


const YokaiBook: FunctionComponent = () => {

  const { classes } = useStyles();
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const rokurokubiSlides = Array.from(Array(rokurokubiImages.length).keys());

  return (
    <div className={classes.root}>
      <Tabs
        orientation="horizontal"
        variant="scrollable"
        scrollButtons="auto"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs"
        className={classes.tabs}
      >
        <Tab label={t("explore:yokai.akaname.title")} {...a11yProps(0)} />
        <Tab label={t("explore:yokai.biwabokuboku.title")} {...a11yProps(1)} />
        <Tab label={t("explore:yokai.jikininki.title")} {...a11yProps(2)} />
        <Tab label={t("explore:yokai.kamaitachi.title")} {...a11yProps(3)} />
        <Tab label={t("explore:yokai.kappa.title")} {...a11yProps(4)} />
        <Tab label={t("explore:yokai.karakasa.title")} {...a11yProps(5)} />
        <Tab label={t("explore:yokai.namahage.title")} {...a11yProps(6)} />
        <Tab label={t("explore:yokai.nurikabe.title")} {...a11yProps(7)} />
        <Tab label={t("explore:yokai.rokurokkubi.title")} {...a11yProps(8)} />
        <Tab label={t("explore:yokai.tengu.title")} {...a11yProps(9)} />
        <Tab label={t("explore:yokai.yanari.title")} {...a11yProps(10)} />
        <Tab label={t("explore:yokai.zashikiwarashi.title")} {...a11yProps(11)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.akaname.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.akaname.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.akaname.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.akaname.title") as string} src={akanameImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.biwabokuboku.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.biwabokuboku.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.biwabokuboku.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.biwabokuboku.title") as string} src={biwaBokuBokuImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.jikininki.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.jikininki.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.jikininki.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.jikininki.title") as string} src={jikininkiImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.kamaitachi.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.kamaitachi.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.kamaitachi.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.kamaitachi.title") as string} src={kamaItachiImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.kappa.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.kappa.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.kappa.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.kappa.title") as string} src={kappaImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={5}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.karakasa.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.karakasa.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.karakasa.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.karakasa.title") as string} src={karaKasaImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.namahage.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.namahage.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.namahage.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.namahage.title") as string} src={namahageImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={7}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.nurikabe.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.nurikabe.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.nurikabe.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.nurikabe.title") as string} src={nurikabeImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={8}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.rokurokkubi.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.rokurokkubi.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.rokurokkubi.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Carousel slides={rokurokubiSlides} imageByIndex={rokurokubiImagesByIndex} altByIndex={rokurokubiAltByIndex} options={{ loop: true }} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={9}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.tengu.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.tengu.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.tengu.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.tengu.title") as string} src={tenguImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={10}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.yanari.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.yanari.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.yanari.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.yanari.title") as string} src={yanariImage} />
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={11}>
        <Grid container spacing={1}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" style={{ marginBottom: "5px", textAlign: "left" }}>{t("explore:yokai.zashikiwarashi.title")}</Typography>
            <Divider />
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.zashikiwarashi.description1")}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" style={{ marginTop: "15px", textAlign: "left" }}>
              {t("explore:yokai.zashikiwarashi.description2")}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <img className={classes.img} alt={t("explore:yokai.zashikiwarashi.title") as string} src={zashikiWarashiImage} />
          </Grid>
        </Grid>
      </TabPanel>
    </div>
  );
};

export default YokaiBook;