import React from "react";
import { TFunction } from "i18next";
import { i18n } from "i18next";
import { PlayerManagementDataService } from "./services/PlayerManagementDataService";
import { PlayerManagementService } from "./services/PlayerManagementService";
import { FormGroup, Label, Input, Form, InputGroup, FormFeedback } from "reactstrap";
import { PlayerData } from "../contracts/PlayerData";
import { Grid, Typography, Link } from "@mui/material";
import { ActionButton, ActionState } from "./ActionButton";
import SaveIcon from "@mui/icons-material/Save";
import SendIcon from "@mui/icons-material/Send";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PopupNotification } from "../common/PopupNotification";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export interface AccountFlowModalComponentProps {
  i18n: i18n;
  t: TFunction;
  isOpen: boolean;
  isLoginFlow: boolean;
  toggleFunction: () => void;
}

export interface AccountFlowModalComponentState {
  emailIsValid: boolean;
  passwordIsValid: boolean;
  passwordVerifyIsValid: boolean;
  inputEmail: string;
  inputPassword: string;
  inputVerifyPassword: string;
  inputTempCode: string;
  registrationFeedback: string | null;
  apiCallActive: boolean;
  registrationError: boolean;
  registrationSuccessful: boolean;
  loginSuccessful: boolean;
  isPasswordlessLoginFlow: boolean;
  isWaitingOnTempLoginCode: boolean;
  errorMessage: string | null;
}

export class AccountFlowModalComponent extends React.Component<AccountFlowModalComponentProps, AccountFlowModalComponentState> {

  private clearActionButtonState: ((override?: ActionState | undefined) => void) | undefined;

  constructor(props: AccountFlowModalComponentProps) {
    super(props);
    this.state = {
      emailIsValid: false,
      passwordIsValid: false,
      passwordVerifyIsValid: false,
      inputEmail: "",
      inputPassword: "",
      inputVerifyPassword: "",
      inputTempCode: "",
      registrationFeedback: null,
      apiCallActive: false,
      registrationError: false,
      registrationSuccessful: false,
      loginSuccessful: false,
      isPasswordlessLoginFlow: false,
      isWaitingOnTempLoginCode: false,
      errorMessage: null
    };
  }

  public render(): JSX.Element {
    if (this.state.loginSuccessful) {
      return <PopupNotification message={"Successfully logged in"} duration={5000} variant={"success"} isOpen={true} />;
    }

    return (
      <div onKeyDown={this.onKeyDown}>
        <BootstrapDialog fullWidth={true} maxWidth={"xs"} open={this.props.isOpen} onClose={this.props.toggleFunction}>
          <BootstrapDialogTitle id="customized-dialog-title" onClose={this.props.toggleFunction}>{this.props.isLoginFlow ? this.props.t("accountflowmodal:header.loginText") : this.props.t("accountflowmodal:header.registerText")}
          </BootstrapDialogTitle>
          <DialogContent dividers>
            {this.getModalBodyUx()}
          </DialogContent>
          <DialogActions>
            <Grid
              container
              direction="row"
              alignItems="flex-start"
            >
              <Grid item xs={6}>
                {this.props.isLoginFlow ?
                  this.state.isPasswordlessLoginFlow
                    ? <Link underline="hover" onClick={() => { this.setState({ isPasswordlessLoginFlow: false, passwordIsValid: false, inputPassword: "" }); }}>Login with password</Link>
                    : <Link underline="hover" onClick={() => { this.setState({ isPasswordlessLoginFlow: true, passwordIsValid: true }); }}>Login with temporary email code</Link>
                  : null}
              </Grid>
              <Grid item xs={6}>
                {this.state.registrationError ? <small>An error occured. Please contact <a href="mailto:administrator@overclockedgames.com?subject=Login/Create account problems"><b>administrator@overclockedgames.com</b></a> to report. </small> : null}
                <small>{this.state.errorMessage}</small>
              </Grid>
            </Grid>
            <ActionButton
              icon={this.props.isOpen ? <SendIcon /> : <SaveIcon />}
              onClick={this.onActionButtonPress}
              disabled={!this.state.emailIsValid || this.state.apiCallActive || !this.state.passwordIsValid || (!this.state.passwordVerifyIsValid && !this.props.isLoginFlow)}
              passClearStateFunction={(childClearState: () => void) => { this.clearActionButtonState = childClearState; }}
            />
          </DialogActions>
        </BootstrapDialog>
      </div>
    );
  }

  public getModalBodyUx(): JSX.Element {

    if (this.state.registrationSuccessful && !this.props.isLoginFlow) {
      return (
        <Typography>{this.props.t("accountflowmodal:body.registrationSuccessful", { "emailAddress": this.state.inputEmail })}</Typography>
      );
    }

    if (this.state.isWaitingOnTempLoginCode) {
      return (<Form>
        <FormGroup>
          <Label for="temp-code-input">Temporary Code</Label>
          <InputGroup>
            <Input
              type="text"
              name="temp-code-input"
              value={this.state.inputTempCode}
              id="temp-code-input"
              placeholder="input temporary code"
              onInput={this.onTempCodeInput}
            />
          </InputGroup>
        </FormGroup>
      </Form>
      );
    }

    if (this.props.isLoginFlow) {
      return (<Form>
        <FormGroup>
          <Label for="emailInput">Email</Label>
          <InputGroup>
            <Input
              type="email"
              name="email"
              id="emailInput"
              placeholder="youremail@example.com"
              valid={this.state.emailIsValid}
              invalid={!this.state.emailIsValid}
              onInput={this.onEmailInput}
            />
            <FormFeedback valid={this.state.emailIsValid}>{this.state.registrationFeedback}</FormFeedback>
          </InputGroup>
        </FormGroup>
        {this.state.isPasswordlessLoginFlow ? null : <FormGroup>
          <Label for="passwordInput">Password</Label>
          <InputGroup>
            <Input
              type="password"
              name="password"
              id="passwordInput"
              placeholder="input a password"
              invalid={!this.state.passwordIsValid}
              onInput={this.onPasswordInput}
            />
            <FormFeedback>Password must be longer than 3 characters</FormFeedback>
          </InputGroup>
        </FormGroup>}
      </Form>
      );
    }
    return (<Form>
      <FormGroup>
        <Label for="emailInput">Email</Label>
        <InputGroup>
          <Input
            type="email"
            name="email"
            id="emailInput"
            placeholder="youremail@example.com"
            valid={this.state.emailIsValid}
            invalid={!this.state.emailIsValid}
            onInput={this.onEmailInput}
          />
          <FormFeedback valid={this.state.emailIsValid}>{this.state.registrationFeedback}</FormFeedback>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label for="password-input">Password</Label>
        <InputGroup>
          <Input
            type="password"
            name="password"
            id="password-input"
            placeholder="input a password"
            invalid={!this.state.passwordIsValid}
            onInput={this.onPasswordInput}
          />
          <FormFeedback>Password must be longer than 3 characters</FormFeedback>
        </InputGroup>
      </FormGroup>
      <FormGroup>
        <Label for="password-verify">Verify Password</Label>
        <InputGroup>
          <Input
            type="password"
            name="password verify"
            id="password-verify"
            placeholder="Type your password again"
            invalid={!this.state.passwordVerifyIsValid}
            onInput={this.onVerifyPasswordInput}
          />
          <FormFeedback>Passwords must match</FormFeedback>
        </InputGroup>
      </FormGroup>
    </Form>
    );
  }

  private onKeyDown = async (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      if (undefined !== this.clearActionButtonState) {
        this.clearActionButtonState("Loading");
      }
      await this.onActionButtonPress();
    }
  };

  private onActionButtonPress = async (): Promise<ActionState> => {

    if (!this.props.isLoginFlow && this.state.registrationSuccessful) {
      this.props.toggleFunction();
      return "Success";
    }

    this.setState({ apiCallActive: true, registrationError: false });
    try {
      if (this.state.isWaitingOnTempLoginCode) {
        await PlayerManagementDataService.CompletePasswordlessEmailLogin(this.state.inputEmail, this.state.inputTempCode);
        const playerData: PlayerData = await PlayerManagementDataService.GetUserData();
        PlayerManagementService.StoreInSession(PlayerManagementService.ProfileDataKey, playerData);
        this.setState({ loginSuccessful: true, apiCallActive: false, isWaitingOnTempLoginCode: false });
        this.props.toggleFunction();
      }
      else if (this.props.isLoginFlow && this.state.isPasswordlessLoginFlow) {
        await PlayerManagementDataService.StartPasswordlessEmailLogin(this.state.inputEmail);
        this.setState({ apiCallActive: false, isWaitingOnTempLoginCode: true });
      }
      else if (this.props.isLoginFlow) {
        await PlayerManagementDataService.Login(this.state.inputEmail, this.state.inputPassword);
        const playerData: PlayerData = await PlayerManagementDataService.GetUserData();
        PlayerManagementService.StoreInSession(PlayerManagementService.ProfileDataKey, playerData);
        this.setState({ loginSuccessful: true, apiCallActive: false });
        this.props.toggleFunction();
      }
      else {
        await PlayerManagementDataService.RegisterNewUser(this.state.inputEmail, this.state.inputPassword);
        await PlayerManagementDataService.Login(this.state.inputEmail, this.state.inputPassword);
        const playerData: PlayerData = await PlayerManagementDataService.GetUserData();
        PlayerManagementService.StoreInSession(PlayerManagementService.ProfileDataKey, playerData);
        this.setState({ loginSuccessful: true, apiCallActive: false, registrationSuccessful: true });
        this.props.toggleFunction();
      }

      this.setState({ errorMessage: "" });
    }
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    catch (e: any) {
      this.setState({ registrationError: true, apiCallActive: false, errorMessage: e.Message });
      return "Failed";
    }
    this.setState({ apiCallActive: false });
    return "Success";
  };

  private onEmailInput = async (event: React.FormEvent<HTMLInputElement>): Promise<void> => {

    const emailInput: string = event.currentTarget.value.toLowerCase();

    if (!this.props.isLoginFlow) {
      this.setState({ apiCallActive: true });
      const checkFeedback: string | null = await PlayerManagementService.GetRegistrationCheckFeedback(emailInput);

      this.setState({ emailIsValid: (checkFeedback === null), registrationFeedback: checkFeedback, inputEmail: emailInput, apiCallActive: false, registrationError: false });
    }
    else {
      this.setState({ emailIsValid: PlayerManagementService.LooksLikeEmail(emailInput), inputEmail: emailInput, registrationError: false });
    }
  };

  private onPasswordInput = (event: React.FormEvent<HTMLInputElement>): void => {

    const passwordInput: string = event.currentTarget.value;
    this.setState({ passwordIsValid: passwordInput.length > 3 });
    this.setState({ inputPassword: passwordInput, registrationError: false });
    if (undefined !== this.clearActionButtonState) {
      this.clearActionButtonState();
    }
  };

  private onTempCodeInput = (event: React.FormEvent<HTMLInputElement>): void => {

    const tempCodeInput: string = event.currentTarget.value;
    this.setState({ inputTempCode: tempCodeInput, registrationError: false });
  };

  private onVerifyPasswordInput = (event: React.FormEvent<HTMLInputElement>): void => {

    const passwordInput: string = event.currentTarget.value;
    this.setState({ passwordVerifyIsValid: passwordInput === this.state.inputPassword });
    this.setState({ inputVerifyPassword: passwordInput, registrationError: false });
    if (undefined !== this.clearActionButtonState) {
      this.clearActionButtonState();
    }
  };
}