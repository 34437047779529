import React, { FunctionComponent } from "react";
import { Container, Fade, Grid, Grow, Paper, Tooltip, Typography, Divider, ImageList } from "@mui/material";
import { Theme, } from "@mui/material/styles";
import { makeStyles, withStyles } from "tss-react/mui";
import { ItemData } from "../contracts/ItemData";
import { Trans } from "react-i18next";

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      backgroundColor: theme.palette.background.paper,
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      paddingTop: "20px"
    },
    header: {
      margin: theme.spacing(3, 2)
    }
  };
});

export interface InventoryProps {
  items: ItemData[];
}

export const Inventory: FunctionComponent<InventoryProps> = (props: InventoryProps) => {
  const { classes } = useStyles();

  const InventoryItemTooltip = withStyles(
    Tooltip,
    (theme: Theme) => ({
      tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
      },
    }));

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Grid container alignItems="center">
          <Grid item xs>
            <Typography gutterBottom variant="h4">
              Inventory
            </Typography>
          </Grid>
        </Grid>
        <Typography color="textSecondary" variant="body2">
          Permanent items attached to your account for use in the Yokai Scrolls universe
        </Typography>
      </div>
      <Divider variant="middle" />
      <Container className={classes.container}>
        <ImageList rowHeight={80} >
          {props.items.map((item: ItemData) => (
            <InventoryItemTooltip TransitionComponent={Fade} TransitionProps={{ timeout: 1600 }} key={item.id}
              title={
                <React.Fragment>
                  <Typography color="inherit">{item.name}</Typography>
                  <em><Trans i18nKey={`items:descriptions.${item.name}`} /></em>
                </React.Fragment>
              }>
              <span>
                <Grow in={true}>
                  <Paper elevation={4} style={{ height: 80, width: 80 }}>
                    <img src={item.imageUri} style={{ height: 80, width: 80 }} alt={item.name}></img>
                  </Paper>
                </Grow>
              </span>
            </InventoryItemTooltip>
          ))}
        </ImageList>
      </Container>
    </div>
  );
};

export default Inventory;